/*
  ################################
  VARIABLES
  ################################
*/
$primary-color: #0080ac;
$primary-color-light: #74b3c7;
$gray: #e7e7e7;

/*
  ################################
  CUSTOM FONT
  ################################
*/

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Merriweather Regular'), local('Merriweather-Regular'), url(https://fonts.gstatic.com/s/merriweather/v21/u-440qyriQwlOrhSvowK_l5-cSZMdeX3rsHo.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Merriweather Regular'), local('Merriweather-Regular'), url(https://fonts.gstatic.com/s/merriweather/v21/u-440qyriQwlOrhSvowK_l5-eCZMdeX3rsHo.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Merriweather Regular'), local('Merriweather-Regular'), url(https://fonts.gstatic.com/s/merriweather/v21/u-440qyriQwlOrhSvowK_l5-cyZMdeX3rsHo.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Merriweather Regular'), local('Merriweather-Regular'), url(https://fonts.gstatic.com/s/merriweather/v21/u-440qyriQwlOrhSvowK_l5-ciZMdeX3rsHo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Merriweather Regular'), local('Merriweather-Regular'), url(https://fonts.gstatic.com/s/merriweather/v21/u-440qyriQwlOrhSvowK_l5-fCZMdeX3rg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*
  ################################
  FONT ICON
  ################################
*/
@font-face {
  font-family: 'fontello';
  src: url('fonts/fontello.eot?99271680');
  src: url('fonts/fontello.eot?99271680#iefix') format('embedded-opentype'),
       url('fonts/fontello.woff2?99271680') format('woff2'),
       url('fonts/fontello.woff?99271680') format('woff'),
       url('fonts/fontello.ttf?99271680') format('truetype'),
       url('fonts/fontello.svg?99271680#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-document:before { content: '\e800'; } /* '' */
.icon-book:before { content: '\e801'; } /* '' */
.icon-video:before { content: '\e802'; } /* '' */
.icon-podcast:before { content: '\e804'; } /* '' */
.icon-plus:before { content: '\e805'; } /* '' */
.icon-enter:before { content: '\f02c'; } /* '' */
.icon-download:before { content: '\f02e'; } /* '' */
.icon-location:before { content: '\f031'; } /* '' */
.icon-website:before { content: '\f08e'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-language:before { content: '\f1ab'; } /* '' */
.icon-plane:before { content: '\f1d8'; } /* '' */

/*
  ################################
  CUSTOM STYLING
  ################################
*/
body {
  font-family: 'Merriweather', serif;
  font-size: 112%;
  color: darken($gray, 50%);
}

a {
  color: $primary-color;

  &:hover { color: $primary-color-light; }
}

main { background-color: lighten($gray, 5%); }

.bg-primary, .btn-primary, .badge-primary, .dropdown-item.active, .dropdown-item:active { background-color: $primary-color !important; }

.jumbotron {
  padding: 3rem 0 0;
  margin-bottom: 0;
  border-radius: 0;

  h1 {
    font-weight: bold;
    text-shadow: 1px 1px 1px darken($gray, 50%);
  }
}

.btn { border: none; }


/* CARDS */
.card {
  font-size: initial;

  h5 { margin-bottom: 0; }
  .card-header { background-color: $gray; }
  .card-img { border-radius: 0; }
}
.card.cv {
  &:last-child { margin-bottom: 0 !important; }
  .card-body { padding: .8rem 1.5rem; }
}
.card.photo {
  a { color: inherit; }
}

/* LIST GROUP */

.list-group-item {
  background-color: lighten($gray, 10%);

  &:hover { background-color: $gray; }
}
.list-group-item-action { color: inherit; }

.accordion {
  a:hover {
    color: inherit;
    text-decoration: none;
  }
}


/* SECTIONS */
article {
  h2, h3 {
    text-shadow: 1px 1px 1px white;
    text-align: center;
    margin: 3rem 0 1.5rem;
  }
  h2 ~ h3 { margin-top: 1rem; }
}

.content {
  > ul { list-style-type: square; }
}

.episode {
  ul li {
    margin-bottom: 1rem;

    &:last-child { margin-bottom: 0; }
  }
}


/* TIMELINE */

.timeline {
  position: relative;
  padding-bottom: 3.5rem;

  &::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 35px;
    height: 100%;
    width: 8px;
    background-color: $gray;
  }

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.timeline-block {
  position: relative;
  margin: 20px 0;
  *zoom: 1;

  &:before, &:after {
    content: '';
    display: table;
  }
  &:after { clear: both; }

  &.current, a:hover {
    margin-top: 0;

    .timeline-content { opacity: 1; }
    .timeline-dot { background-color: $primary-color; }
  }
  &.current .timeline-dot { animation: pulse 1.5s infinite; }
  &:last-child { margin-bottom: 0; }
}

@keyframes pulse {
  0% { box-shadow: none; }
  50% {
    border-color: $primary-color-light;
    box-shadow: 0 0 0 8px lighten($primary-color-light, 20%);
  }
  100% { box-shadow: none; }
}

.timeline-dot {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  border-radius: 1.5em;
  background-color: $primary-color-light;
  color: white;
  font-weight: bold;
  line-height: 2.4em;
  width: 3em;
  text-align: center;
  font-size: 25px;
  border: 8px solid $gray;
  box-shadow: none;
}

.timeline-content {
  position: relative;
  margin-left: 100px;
  background-color: white;
  padding: 15px;
  *zoom: 1;
  border-radius: 10px;
  opacity: .5;

  &:before, &:after {
    content: '';
    display: table;
  }
  &:after { clear: both; }

  &::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-right: 10px solid white;
  }

  p { font-size: initial; }

  .time {
    display: inline-block;
    float: left;
    padding: 0;
    text-shadow: 1px 1px 1px $gray;
    font-weight: bold;
    font-size: 120%;
    color: $primary-color;
  }
}

@media only screen and (min-width: 720px) {
  .timeline {
    &::before {
      left: 50%;
    }
  }
  .timeline-block {
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }

    &:nth-child(odd) .timeline-content {
      float: left;
    }

    &:nth-child(even) .timeline-content {
      float: right;

      &::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: white;
      }
    }
  }
  .timeline-dot {
    left: 50%;
    margin-left: -1.4em;

    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ();
    -webkit-backface-visibility: hidden;
  }
  .timeline-content {
    margin-left: 0;
    width: 44%;
    padding: 20px 25px 5px 25px;

    &::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: white;
    }

    .time {
      position: absolute;
      width: 100%;
      left: 130%;
      top: 22px;
      text-shadow: 1px 1px 1px white;
    }
  }
}

.footer-links { font-size: 75%; }